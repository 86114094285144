import React from 'react'
import { Container } from 'react-bootstrap'

import Social from '~components/Social'

import * as s from './Footer.module.scss'

const Footer = () => {
  return (
    <Container as="footer" className={s.footer}>
      <Social />
    </Container>
  )
}

export default Footer
