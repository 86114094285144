import React from 'react'

import Icon from '../Icon'

import DATA from './constants'

import * as s from './Social.module.scss'

const Social = () => {
  return (
    <ul className={s.social}>
      {DATA.map(({ name, link }) => (
        <li key={name}>
          <a href={link} aria-label={name} target="_blank" rel="noreferrer">
            <Icon name={name.toLowerCase()} size={40} />
          </a>
        </li>
      ))}
    </ul>
  )
}

export default Social
